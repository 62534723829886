/* eslint-disable camelcase */
import React, { memo, useEffect } from 'react';
import Footer from '@/components/footer';
import pvLog from '@/utils/logSend';
import style from './index.module.scss';

const endorsement = [
  '//img10.360buyimg.com/paipai/jfs/t1/140649/25/38250/92301/64bf409bF74d690d5/9ee26f006b0cd8ff.png',
  '//img10.360buyimg.com/paipai/jfs/t1/206156/21/37622/132987/64bf40c0F14cb52f3/b186d82c3528d23e.png',
  '//img10.360buyimg.com/paipai/jfs/t1/184085/31/34831/98188/64bf406bF158ad6c2/410cb2830bb99c36.png',
];

const Index = () => {
  useEffect(() => {
    const page_id = null;
    const event_id = 'ppgwm_home';
    const ext = {
      eventName: '拍拍官网m端_首页',
    };

    pvLog.sendPv(page_id, {
      event_id,
      ext,
    });
    setTimeout(() => {
      pvLog.sendClick(event_id, {
        page_id,
        ext,
      });
    }, 600);
  }, []);

  return (
    <div className={style.index}>
      <div className={style.header}>
        <Footer />
      </div>
      <div className={style.footerBg}>
        <div className={style.footerWrapper}>
          <div className={style.titleBox}>
            <div className={style.titileMain}>爱回收严选</div>
            <div className={style.titleSub}>严控货源&nbsp;&nbsp;品质保证</div>
          </div>
          {/* 质检图 */}
          <div className={style.images}>
            {endorsement.map((item) => (
              <div className={style.imagesItem} key={item}>
                <img className={style.beishu} src={item} alt="" />
              </div>
            ))}
          </div>
          {/* 拍拍严选 */}
          {/* <div className={style.inspect}>
            <img
              className={style.logo}
              src="//img10.360buyimg.com/paipai/jfs/t1/180447/36/19108/5787/61160586Ee8378b6e/50242f434d46f38c.png"
              alt=""
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default memo(Index);
