import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import jdProfiler from '@jmfe/jd-profiler';
import './index.scss';
import Index from './pages/index';

if (process.env.NODE_ENV === 'production') {
  try {
    // 京东H5页面性能提供上报 http://npm.m.jd.com/package/@jmfe/jd-profiler
    jdProfiler.init({ flag: 19, autoReport: true });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<Index />, document.getElementById('root'));
