import httpJsonp from '@/utils/request';
import { getDeviceType, getEnvType } from '@pai/paipai-util';
import loader from '@/utils/loader';
import { DOWNLOAD_URL } from '@/config/config';

/**
 * 微信环境下载提示
 */
const showWxTips = () => {
  const _getEnvType = getEnvType();
  const tipsClassName = 'wx-download-tips';
  const tipsHasShow = !!document.body.querySelector('.wx-download-tips');
  if (_getEnvType === 'wx' && !tipsHasShow) {
    const wxDownLoadMask = document.createElement('div');
    wxDownLoadMask.className = tipsClassName;
    document.body.appendChild(wxDownLoadMask);
  }
};

/**
 * 跳转下载链接
 */
const toDownLoad = () => {
  const _deviceType = getDeviceType(); // 1：安卓 ; 2：IOS ; 0 其他
  window.location.href = _deviceType === 2 ? DOWNLOAD_URL.APP_STORE : DOWNLOAD_URL.APK;
  showWxTips();
};

/**
 * h5协议打开paipai
 */
const h5OpenClinent = () => {
  const startTime = Date.now();

  const ifr = document.createElement('iframe');

  ifr.src = DOWNLOAD_URL.SCHEME;
  ifr.style.display = 'none';
  document.body.appendChild(ifr);

  /**
   * 未正常打开app，则跳转下载
   */
  const t = setTimeout(() => {
    const endTime = Date.now();

    if (!startTime || endTime - startTime < 1200) {
      toDownLoad();
    }
  }, 1000);

  const handle = () => {
    clearTimeout(t);
    document.body.removeChild(ifr);
    window.onblur = null;
    document.removeEventListener('visibilitychange', handle);
  };

  window.onblur = handle;
  document.addEventListener('visibilitychange', handle);
};

// 微信唤起app协议
const wxOpenClient = async (jumpUrl = DOWNLOAD_URL.SCHEME.split('://')[1]) => {
  try {
    await loader.load(['jweixin']);
    const res = await httpJsonp('//bizgw.jd.com/wx/signature', {
      mpSource: 1,
      url: encodeURIComponent(window.location.href.split('#')[0]),
    });
    const { nonceStr, signature, timestamp } = res.data || {};
    if (!nonceStr || !signature || !timestamp) {
      toDownLoad();
      return;
    }
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: 'wxb0826665336997f9', // 必填，公众号的唯一标识
      timestamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature, // 必填，签名
      jsApiList: ['launchApplication'], // 必填，需要使用的JS接口列表
    });
    window.wx.ready(() => {
      setTimeout(() => {
        window.WeixinJSBridge.invoke(
          'launchApplication',
          { appID: 'wx179d062a16638f00', parameter: jumpUrl, extInfo: jumpUrl },
          (res2) => {
            // console.log('WeixinJSBridge.invoke', res2);
            if (/launchApplication:fail/.test(res2.err_msg)) {
              toDownLoad();
            }
            // console.log(`返回了${JSON.stringify(res2)}`);
          },
        );
      }, 0);
    });
    window.wx.error((e) => {
      // eslint-disable-next-line no-console
      console.log('wx.error', e);
      toDownLoad();
    });
  } catch (e) {
    toDownLoad();
  }
};

// eslint-disable-next-line import/prefer-default-export
export const openOrDownload = async () => {
  const _getEnvType = getEnvType(); // wx: 微信 qq: QQ jd: 京东app paipai: 拍拍app

  switch (_getEnvType) {
    case 'wx':
      await wxOpenClient();
      break;
    default:
      h5OpenClinent();
      break;
  }
};
