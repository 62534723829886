import React, { memo, useCallback } from 'react';
import pvLog from '@/utils/logSend';
import { openOrDownload } from './download';
import './index.scss';

/**
 * footer
 * @returns
 */
const Footer = () => {
  const toH5 = useCallback(() => {
    pvLog.sendClick('ppgwm_home_go2', {
      ext: {
        eventName: '拍拍官网m端_首页_极速体验',
      },
    });

    setTimeout(() => {
      window.location.href =
        'https://pro.m.jd.com/mall/active/LHGZv1DrGkva1jNpUkKFuNFN6oo/index.html';
    }, 200);
  }, []);

  const toDownLoad = useCallback(() => {
    pvLog.sendClick('ppgwm_home_download', {
      ext: {
        eventName: '拍拍官网m端_首页_立即下载',
      },
    });

    setTimeout(() => {
      openOrDownload();
    }, 200);
  }, []);

  return (
    <div className="footer-content">
      <div onClick={toDownLoad} className="footer-btn footer-btn_2" role="button" tabIndex="0">
        <img
          className="btnText"
          src="//img10.360buyimg.com/paipai/jfs/t1/196273/5/18074/7200/61160aadEa46345a8/f7584475fd3238e3.png"
          alt=""
        />
      </div>
      <div onClick={toH5} className="footer-btn" role="button" tabIndex="0">
        <img
          className="btnText"
          src="//img10.360buyimg.com/paipai/jfs/t1/182631/35/19659/12335/61160a94E21b02e02/94046400929a2f9f.png"
          alt=""
        />
      </div>
    </div>
  );
};

export default memo(Footer);
