class Loader {
  /**
   * 判断类型
   * @param {*} url
   */
  static getType(url) {
    const isCssReg = /\.css(?:\?|$)/i;
    return isCssReg.test(url) ? 'link' : 'script';
  }

  constructor(config) {
    // 用于存储已经加载过的资源信息，包括 node 节点，节点类型，是否加载成功，是否在dom中
    this.moduleMap = {}; // { '//xx.js': { node: null, type: 'script', isLoad: false, isInDom: false } }
    this.headNode = document.querySelector('head');
    this.config = {
      resources: {},
      ...config,
    };
  }

  /**
   * 绑定事件
   * @param {*} el
   * @param {*} resolve
   */
  addListener(url) {
    const module = this.moduleMap[url];
    const { node } = module;
    let resolve;
    let reject;
    const promise = new Promise((res, req) => {
      resolve = res;
      reject = req;
    });
    // 报错回调
    const onError = (e) => {
      Object.assign(module, {
        isLoad: false, // 加载失败
        node: null, // 清空节点
        isInDom: false, // 不在dom中
      });
      if (node && node.parentNode) {
        node.parentNode.removeChild(node); // 删除dom节点
      }
      reject(e);
    };
    // 成功回调
    const onLoad = () => {
      module.isLoad = true; // 加载成功
      node.removeEventListener('load', onLoad);
      node.removeEventListener('error', onError);
      resolve();
    };
    node.addEventListener('load', onLoad, false);
    node.addEventListener('error', onError, false);

    return promise;
  }

  /**
   * 加载单个标签
   * @param {*} url
   * @param {*} resolve
   */
  async loadByType(url) {
    const type = Loader.getType(url);
    const node = document.createElement(type);

    if (type === 'link') {
      node.rel = 'stylesheet';
      node.href = url;
    } else {
      node.type = 'text/javascript';
      node.async = true;
      node.src = url;
    }

    if (!this.moduleMap[url]) {
      this.moduleMap[url] = {
        node,
        type,
        isLoad: false,
        isInDom: false,
      };
    }

    const module = this.moduleMap[url];
    const { isLoad, isInDom } = module;
    if (isLoad) {
      // 已经加载成功的，直接resolve
      return Promise.resolve();
    }
    // 添加事件监听
    const result = this.addListener(url);
    if (!isInDom) {
      // 如果不在dom中，则添加
      this.headNode.appendChild(node);
      // (实际并没有立刻渲染进dom)
      module.isInDom = true;
    }
    return result;
  }

  /**
   * 加载所需的资源数组
   * @param {*} resources 配置的资源数组
   */
  async load(resources = []) {
    // 资源URL展开去重
    const urls = resources?.reduce(
      (pv, cv) => Array.from(new Set([...pv, ...(this.config.resources[cv] || [])])),
      [],
    );
    return Promise.all(urls?.map((url) => this.loadByType(url)));
  }
}

const loader = new Loader({
  resources: {
    // jssdk: ['//h.360buyimg.com/jssdk/js/jssdk.1.0.7.min.js'],
    jweixin: ['//ydcx.360buyimg.com/plugin/wx/jweixin-1.4.0.js'],
    // jweixin: ['//res.wx.qq.com/open/js/jweixin-1.6.0.js'],
    logSendSdk: ['//wl.jd.com/unify.min.js'],
    // jdEid: ['//gia.jd.com/m.html', '//gias.jd.com/js/m.js'],
    // qqshare: ['//ydcx.jd.com/js/base/qq/share.js']
    // swiper: [
    //   '//ydcx.360buyimg.com/plugin/swiper/swiper.min.js',
    //   '//ydcx.360buyimg.com/plugin/swiper/swiper.min.css',
    // ],
  },
});

// loader.load(['jdEid']);

export default loader;
