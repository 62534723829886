/* eslint-disable no-console */
/* eslint-disable no-return-await */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
// import { lOG_SITE_ID } from '@/config/config';
import { getEnvType } from '@pai/paipai-util';
import loader from './loader';

// eslint-disable-next-line camelcase
const lOG_SITE_ID = 'JA2017_111809';

/**
 * 获取当前h5环境 wx-微信;qq-手Q;h5-浏览器;jd-京东app;paipai-拍拍app;xcx-小程序中h5
 * @returns {string}
 */
const getEnv = () => {
  let type = 'h5';
  const ua = window.navigator.userAgent.toLowerCase();
  if (window.__wxjs_environment === 'miniprogram' || ua.indexOf('miniprogram') > -1) {
    type = 'xcx';
  } else {
    type = getEnvType() || 'h5';
  }

  return type;
};

/**
 * 初始化
 */
const setIt = (param = {}, type = 'pv') => {
  try {
    const { MPing } = window;
    if (!MPing) {
      return;
    }
    if (type === 'pv') {
      const pv = param.pageId ? new MPing.inputs.PV(param.pageId) : new MPing.inputs.PV();
      delete param.pageId;
      Object.assign(pv, param);
      new MPing().send(pv);
    } else if (type === 'order') {
      // http://docs.stream.jd.com/02_SDK%20%E6%96%87%E6%A1%A3/06_M%E7%AB%AF%E6%8E%A5%E5%85%A5%E6%89%8B%E5%86%8C/report-guide-order/
      const { skuIds = [], ...otherParam } = param;
      (skuIds || []).forEach((skuId) => {
        const order = new MPing.inputs.Order(skuId);
        Object.assign(order, otherParam);
        new MPing().send(order);
      });
    } else {
      const click = param.eventId
        ? new MPing.inputs.Click(param.eventId)
        : new MPing.inputs.Click();
      delete param.eventId;
      Object.assign(click, { env: getEnv() }, param);
      click.updateEventSeries();
      new MPing().send(click);
    }
  } catch (err) {
    console.log(err);
  }
};

let isFirst = true;

/**
 * 埋点 文档地址：https://cf.jd.com/pages/viewpage.action?pageId=79700612
 * @param type {pv: 上报pvuv click：上报点击}
 * @param paramObj
 * pv -> {pageName: 页面名称}
 * click -> {eventId: 事件ID, event_param: click的参数}
 */
const logSend = async (paramObj = {}, type = 'pv') => {
  try {
    window.jap = {
      siteId: lOG_SITE_ID, // 站点编号，必传字段
      autoLogPv: false, // 是否自动上报pv
      anchorpvflag: false, // 单页自动上报
    };
    await loader.load(['logSendSdk']);
    setIt(paramObj, type);
    if (isFirst) {
      isFirst = false;
      try {
        // 链接点击型埋点 需要初始化
        window.MPing.inputs.Click.attachEvent();
      } catch (err) {
        // console.log(err);
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const pvLog = {
  //   log: logUtil.init(),
  log: {
    setPage: () => {},
    sendPv: () => {
      console.log('log.sendPv');
    },
  },
  init() {
    // this.log.set({
    //   siteId: 'JA2019_5112300'
    // })
  },
  setPageId: (pageId) => {
    // this.log.set({
    //   pageId
    // })
  },
  setOpenid: (openid) => {
    // this.log.set({
    //   openid
    // })
  },
  setPage: () => {},
  sendExposure: () => {},
  sendPv: (pageId, config = {}) => {
    const _config = { ...config };
    _config.ext = JSON.stringify({
      ...(_config.ext || {}),
    });
    // pv上报
    setTimeout(async () => await logSend({ ..._config, pageId }, 'pv'), 500);
  },
  sendClick: async (eventId, config = {}) => {
    if (!eventId) {
      // 没有eventId不报埋点
      return;
    }
    const _config = { ...config };
    const extend_field = JSON.stringify({
      ...(_config.ext || {}),
      // ...globalData.extraData
    });
    delete _config.ext;
    const event_param =
      typeof _config.param === 'object' ? JSON.stringify(_config.param) : _config.param;
    delete _config.param;

    await logSend(
      {
        eventId,
        event_param,
        extend_field,
        ..._config,
      },
      'click',
    );
  },
};

pvLog.init();

export default pvLog;
