import jsonp from 'jsonp';
import { checkAndChangeAPIHostname } from '@pai/paipai-util';
import { jsonToString } from './tools';
/**
 * jsonp请求
 * @param url 请求路径
 * @param params 入参
 * @returns {Promise<any>} 结果promise
 */
export default function httpJsonp(url, params = {}) {
  let _url = url; // eslint-disable-line
  try {
    _url = checkAndChangeAPIHostname(url);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
  return new Promise((resolve, reject) => {
    jsonp(
      _url + jsonToString(params),
      {
        timeout: 10000,
        // name: 'callabck'
      },
      (err, res) => {
        if (err) {
          reject(err);
        } else {
          return ['0', '200'].indexOf(String(res.code)) !== -1 ||
            (res.Identity && res.Identity.IsAuthenticated)
            ? resolve(res)
            : reject(res);
        }
        return null;
      },
    );
  });
}
